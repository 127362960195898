<template>
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4311 7H56.8524C59.7631 7 62.1417 9.34247 62.1417 12.2089V16H1.14172V12.2089C1.14172 9.34247 3.52038 7 6.4311 7Z"
      fill="#EEEEF4"
    />
    <path
      d="M7.18898 6H56.6892C60.2428 6 63.1417 8.90466 63.1417 12.4652V56.8159C63.1417 60.2203 60.3675 63 56.9698 63H7.2825C3.88481 63 1.14172 60.2515 1.14172 56.8471V12.0592C1.14172 8.71726 3.85364 6.03123 7.15781 6.03123L7.18898 6Z"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.14172 16L63.1417 16"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1417 2V9.87097"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M50.1417 2L50.1417 9.87097"
      stroke="#797997"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M40.0365 46.0341C40.0467 46.048 40.0573 46.0616 40.0682 46.075C40.0668 46.0733 40.067 46.0736 40.0683 46.076C40.0747 46.0879 40.1083 46.1499 40.1283 46.2825C40.1508 46.4316 40.1451 46.6034 40.1098 46.757C40.0801 46.8864 40.0392 46.9634 40.0135 47L24.2739 47C24.2476 46.9635 24.2052 46.8856 24.1745 46.7537C24.1387 46.5999 24.1325 46.4284 24.1545 46.2799C24.1738 46.1492 24.2065 46.088 24.213 46.0759C24.2144 46.0732 24.2146 46.073 24.2129 46.075C24.2246 46.0608 24.2358 46.0463 24.2466 46.0315L29.6834 38.5899C29.9402 38.2385 29.9402 37.7615 29.6834 37.4101L24.2466 29.9685C24.2435 29.9643 24.2404 29.9601 24.2373 29.9559C24.2335 29.9408 24.2276 29.9088 24.227 29.8546C24.2255 29.7308 24.2548 29.5657 24.323 29.4001C24.3917 29.2333 24.4792 29.115 24.5521 29.0504C24.5861 29.0202 24.6099 29.0079 24.6206 29.0034C24.6293 28.9997 24.6317 29 24.6331 29L40.0136 29C40.0393 29.0366 40.0802 29.1136 40.1099 29.243C40.1451 29.3966 40.1508 29.5684 40.1284 29.7175C40.1084 29.8501 40.0748 29.9121 40.0684 29.924C40.0671 29.9264 40.0669 29.9267 40.0683 29.925C40.0574 29.9384 40.0468 29.952 40.0366 29.9659L34.5627 37.4075C34.3034 37.7599 34.3034 38.2401 34.5627 38.5925L40.0365 46.0341Z"
      stroke="#B0B0D3"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M31.333 40.312C31.7323 39.7629 32.5511 39.7629 32.9505 40.312L36.1417 44.7H28.1417L31.333 40.312Z"
      fill="#797997"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>